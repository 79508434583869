import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';

export default function(props){
    function handleChange(event){
        props.setGstType(() => event.target.value);
    }
    return(
        <FormControl 
        sx={{mt: 1, mb: 1, displayPrint: 'none'}}
        >
            <FormLabel id="gst">GST Type</FormLabel>
            <RadioGroup
            row
            aria-labelledby="gst-radio"
            name="gst-radio"
            value={props.gstType}
            onChange={handleChange}
            >
                <FormControlLabel value="18" control={<Radio />} label="18%" />
                <FormControlLabel value="9" control={<Radio />} label="(9+9)%" />
            </RadioGroup>
        </FormControl>
    )
}