import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState} from 'react';
import ItemForm from './ItemForm';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from '@mui/material/Typography';

export function ItemTable(props){
    let [total, setTotal] = useState(0)

    //change the total amount after each change in the state
    useEffect(() => {
        let indTotal = castors.map((item, index) => {
            return (Number(item.price)*Number(item.quantity))
        })
        total = indTotal.reduce((partialSum, a) => partialSum + a, 0);
        total = total+(total*0.18);
        setTotal(total);
    })


    //deletes the item from table
    function itemDelete(index){
        castors.splice(index, 1)
        let tempState = [...castors];
        
        setCastors(() => {
            return tempState;
        });
    }
    let [castors, setCastors] = useState([]);
    return (
        <>
        <Typography 
        variant="h2"
        sx={{mt: 1, displayPrint: 'none'}}
        >Add Items</Typography>

        <TableContainer 
        component={Paper}
        sx={{mt: 1, displayPrint: 'none'}}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Remove</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {castors.map((item, index) => {
                        return(
                        <TableRow key={index}>
                            <TableCell>{item.itemName}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.price}</TableCell>
                            <TableCell>
                                <IconButton color='error' onClick={() => itemDelete(index)}>
                                    <DeleteForeverIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        )
                    })}
                    <TableRow>
                        <TableCell
                        colSpan={3}>
                            <b>Total amount (including GST)</b>
                        </TableCell>
                        <TableCell>
                            {total}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        <ItemForm
        castors = {castors}
        total={total}
        setCastors = {setCastors}
        />
        
        </>
    )
}