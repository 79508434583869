import { Button } from "@mui/material"
import { Routes, Route } from "react-router-dom"
import { Form } from "./Form"
import { useNavigate } from "react-router-dom"
import ArticleIcon from '@mui/icons-material/Article';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {SelectType} from "./GetInvoices/SelectType"

export default function Select() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SelectButtons />} />
        <Route path='/new' element={<Form />} />
        <Route path='/invoices/*' element={<SelectType />}/>
      </Routes>

    </>
  )
}

function SelectButtons() {
  let navigate = useNavigate();
  return (
    <div
      className="grid-center"
    >
      <Button
        variant='contained'
        size='large'
        onClick={() => navigate('/new')}
        endIcon={<AddBoxIcon />}
      >
        Create new
      </Button>
      <Button
        variant='contained'
        size='large'
        onClick={() => navigate('/invoices')}
        endIcon={<ArticleIcon />}
      >
        Invoices
      </Button>
    </div>
  )
}