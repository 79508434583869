import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ItemTable from "./ItemTable";
import { Button } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import Bill from "../Bill/Bill";
import { useContext, useState } from "react";
import { typeContext } from "./SelectType";
import { Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveDialog from "./SaveDialog";

export default function Single(props) {
  let type = useContext(typeContext);
  let [createButton, setCreateButton] = useState(() => {
    if (type == "PERFORMA") {
      return "inline-flex";
    } else {
      return "none";
    }
  });
  let [dialog, setDialog] = useState(false);

  let castors = [];
  let billNumber = props.details[0];
  let date = props.details[1];
  let party = props.details[2];
  let itemNameArr = props.details[3].split(",");
  let quantityArr = props.details[4].split(",");
  let priceArr = props.details[5].split(",");

  for (let i = 0; i < itemNameArr.length; i++) {
    let singleCastor = {
      itemName: itemNameArr[i],
      price: priceArr[i],
      quantity: quantityArr[i],
    };
    castors.push(singleCastor);
  }
  let total = props.details[6];
  let gstType = props.details[7];
  let address = props.details[8];
  let gst = props.details[9];
  let [details, setDetails] = useState({
    castors: castors,
    label: party,
    date: date,
    address: address,
    total: total,
    gst: gst,
    number: billNumber,
    gstType: gstType,
    type: type,
  });

  return (
    <>
      <Container maxWidth="lg" sx={{ displayPrint: "none" }}>
        <Typography variant="h2">Details</Typography>
        <Typography variant="body1">Bill Number: {billNumber}</Typography>
        <Typography variant="body1">Date: {date}</Typography>
        <Typography variant="body1">Name: {party}</Typography>
        <Typography variant="body1">GST: {gst}</Typography>
        <Typography variant="h2">Items</Typography>
        <ItemTable items={castors} />
        <Stack direction="row" justifyContent="space-evenly">
          <Button
            variant="contained"
            onClick={() => window.print()}
            startIcon={<PrintIcon />}
            sx={{ mt: 1 }}
          >
            Print
          </Button>
          <Button
            variant="contained"
            color="warning"
            sx={{ mt: 1, display: createButton }}
            startIcon={<AddIcon />}
            onClick={() => setDialog(true)}
          >
            Create Invoice
          </Button>
        </Stack>
        <SaveDialog
          open={dialog}
          setDialog={setDialog}
          setDetails={setDetails}
          {...details}
          details={props.details}
        />
      </Container>
      {/* Final Bill component */}
      <Bill {...details} />
    </>
  );
}
