import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import App from './App.js'
import './css/styles.css'

let authApi = document.createElement('script');
authApi.src = 'https://accounts.google.com/gsi/client';
authApi.async = true;
document.body.append(authApi);

let api = document.createElement('script');
api.src = 'https://apis.google.com/js/api.js';
api.async = true;
api.onload = a;
document.body.append(api);

async function a(){
    console.log('gapi loaded')
    try{
        await window.gapi.load('client');
    }catch(err){
        console.log(err.name, err.message);
    }
    
    
}



ReactDOM.render(<App/>, document.getElementById('root'));