import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function SaveDialog(props) {
  let [date, setDate] = useState(props.date);
  let [number, setNumber] = useState(props.number);
  let [dialogActionText, setDialogActionText] = useState("save");
  let [loading, setLoading] = useState(false);

  //Set dialogue action to either print or save
  async function updateAction() {
    if (dialogActionText == "save") {
      props.setDetails(() => {
        return {
          castors: props.castors,
          label: props.label,
          date: date,
          address: props.address,
          total: props.total,
          gst: props.gst,
          number: number,
          gstType: props.gstType,
          type: props.type,
        };
      });
      let result = await upload();
      if (result) {
        setDialogActionText("Print");
      } else {
        return;
      }
    } else {
      window.print();
    }
  }

  //upload function

  async function upload() {
    setLoading(true);
    let response;
    let valueRangeBody = {
      values: [
        [
          number,
          date,
          props.label,
          props.details[3],
          props.details[4],
          props.details[5],
          props.total,
          props.gstType,
          props.address,
          props.gst,
        ],
      ],
    };
    try {
      response = await window.gapi.client.sheets.spreadsheets.values.append(
        {
          spreadsheetId: "1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4",
          range: `Sheet2!A1:H100`,
          valueInputOption: "USER_ENTERED",
        },
        valueRangeBody
      );
      alert("Update Success");
      setLoading(false);
      console.log(response.status);
      return response.status;
    } catch (err) {
      console.log(err);
      alert(err.result.error.message)
      setLoading(false);
    }
  }

  //reset billnumber and date to default
  function reset() {
    setDate(props.date);
    setNumber(props.number);
  }
  return (
    <Dialog open={props.open} onClose={() => props.setDialog(false)} sx={{ displayPrint: "none" }}>
      <DialogTitle>Save as Invoice</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Provide the date and invoice number to save the performa invoice as
          final Tax invoice in the database.
        </DialogContentText>
        <Typography variant="body1">Date</Typography>
        <input
          type="date"
          className="datePicker"
          id="date"
          value={date}
          onChange={() => setDate(document.getElementById("date").value)}
        ></input>
        <TextField
          label="Bill Number"
          id="number"
          variant="outlined"
          sx={{ mb: 1, displayPrint: "none" }}
          fullWidth={true}
          value={number}
          onChange={() => setNumber(document.getElementById("number").value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={reset}>Reset</Button>
        <LoadingButton onClick={updateAction} loading={loading}>
          {dialogActionText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
