import { TextField, Button,Stack } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import { useState, useEffect } from "react";
import { ItemTable } from "./Items/ItemTable.js";
import { LoadingButton } from "@mui/lab";
import Typography from '@mui/material/Typography';

export default function ShowDetails(props){
    let [loadingDisable, setLoadingDisable] = useState(false);
    let [done, setDone] = useState(false);
    let [opDisplay, setOpDisplay] = useState ('flex');
    let [details, setDetails] = useState({});
    let [addCastors, setAddCastors] = useState(false);
    let [loading, setLoading] = useState(false);

    //update details on change of values
    function updateDetails(){
        setDetails(() => {
            return {
                label: document.getElementById('name').value,
                gst: document.getElementById('gst').value,
                address: document.getElementById('address').value,
                date: document.getElementById('date2').value,
                number: props.number,
            }
        })
    }

    //upload the party details to the googleSheets
    async function handleSave(){
        setLoading(true)
        let response;
        let valueRangeBody = {
            "values": [
                [details.label, details.address, details.gst]
            ]
        }
        try{
            response = await window.gapi.client.sheets.spreadsheets.values.append({
                spreadsheetId: '1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4',
                range: 'Sheet1!A1:C100',
                valueInputOption: 'USER_ENTERED',
            }, valueRangeBody) 
            alert('Update Success')
            setLoading(false)
            setLoadingDisable(true);
        }catch(err){
            alert('Update Failed')
            console.log(err)
            setLoading(false)
        }
    }

    //set the details if the data is already selected in previous step
    useEffect(() => {
        if(!props.addItem){
            setAddCastors(true);
            
            setDetails(() => {
                return {
                    label: props.label,
                    address: props.address,
                    gst: props.gst,
                    date: props.date,
                    number: props.number,
                }
            })
            
        }
    },[])

    //reset the edit state to make corrections
    function edit(event){
        if(event.target.id == 'edit'){
        setDone(false);
        setOpDisplay('flex')
        setAddCastors(false)
        }
        document.getElementById('date2').disabled = false
    }
    

    //disable the edit state for confirmation
    function disable(){
        setDone(true);
        setOpDisplay('none')
        props.setSelectedParty(() => details);
        setAddCastors(true);
        document.getElementById('date2').disabled = true
    }
    if(props.addItem && addCastors){
        return (
            <>
            <Typography 
            variant="h2"
            sx={{mb: 1, displayPrint: 'none'}}
            >Details</Typography>

            <TextField label='Bill Number'
            id="number"
            variant="outlined"
            sx={{mb: 1, displayPrint: 'none'}}
            fullWidth={true}
            disabled={true}
            value={props.number}
            />

            <input type='date' className='datePicker' id='date2'></input>
            <TextField label='Name'
            id="name"
            variant="outlined"
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
            disabled={done}
    
            />
            <TextField label='Address'
            id="address"
            variant="outlined"
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
            disabled={done}
    
            />
            <TextField label='GST'
            id="gst"
            variant="outlined"
            sx={{mb: 1,displayPrint: 'none' }}
            fullWidth={true}
            disabled={done}
    
            />
            <Stack
            direction="row"
            justifyContent="space-evenly"
            sx={{displayPrint: 'none'}}
            
            >
            <LoadingButton loading={loading} onClick={handleSave} variant='contained' startIcon={<SaveIcon/>} color='success' disabled={loadingDisable}>Save Details</LoadingButton>
            <Button id='edit' onClick={edit} variant= 'contained' startIcon={<RefreshIcon/>} >Edit</Button>
            </Stack>
            <ItemTable/>
            </>
        )
    }else if(props.addItem){

        return(
            <>
            <Typography 
            variant="h2"
            sx={{mb: 1, displayPrint: 'none'}}
            >Details</Typography>

            <TextField label='Bill Number'
            id="number"
            variant="outlined"
            sx={{mb: 1, displayPrint: 'none'}}
            fullWidth={true}
            disabled={done}
            value={props.number}
            onChange={updateDetails}
            />

            <input type='date' className='datePicker' id='date2' onChange={updateDetails}></input>
            <TextField label='Name'
            id="name"
            variant="outlined"
            sx={{mb: 1 }}
            fullWidth={true}
            disabled={done}
            onChange={updateDetails}

    
            />
            <TextField label='Address'
            id="address"
            variant="outlined"
            sx={{mb: 1 }}
            fullWidth={true}
            disabled={done}
            onChange={updateDetails}

    
            />
            <TextField label='GST'
            id="gst"
            variant="outlined"
            sx={{mb: 1 }}
            fullWidth={true}
            disabled={done}
            onChange={updateDetails}

    
            />

            <Stack
            direction="row"
            justifyContent="space-evenly"
            sx = {{display: opDisplay}}
            >
                <Button id="done" onClick={disable} variant='contained' startIcon={<CheckIcon/>} color='success'>done</Button>
                <Button id='reset' onClick={props.reset} variant= 'contained' startIcon={<RefreshIcon/>} color='error'>Cancel</Button>
            </Stack>

            </>
        )
    }else{
        return(
        <>
            <Typography 
            variant="h2"
            sx={{mb: 1, displayPrint: 'none'}}
            >Details</Typography>

            <TextField label='Bill Number'
            id="number"
            variant="outlined"
            sx={{mb: 1, displayPrint: 'none'}}
            fullWidth={true}
            disabled={true}
            value={props.number}
            />

            <TextField label='Date'
            variant="outlined"
            value={props.date}
            disabled={true}
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
            />
            <TextField label='Name'
            variant="outlined"
            value={props.label}
            disabled={true}
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
    
            />
            <TextField label='Address'
            variant="outlined"
            value={props.address}
            disabled={true}
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
    
            />
            <TextField label='GST'
            variant="outlined"
            value={props.gst}
            disabled={true}
            sx={{mb: 1, displayPrint: 'none' }}
            fullWidth={true}
    
            />
            <Button 
            id='reset' 
            onClick={props.reset} 
            variant= 'contained'
            sx={{displayPrint: 'none'}}
            >Cancel</Button>
            <ItemTable/>
            </>
        )
    }

}