import React from "react";
import GetInvoices from "./GetInvoices";
import { Button } from "@mui/material";
import { useState } from "react";

let typeContext = React.createContext("");
export { typeContext };
export function SelectType() {
  let [type, setType] = useState("");
  if (type) {
    return (
      <typeContext.Provider value={type}>
        <GetInvoices type={type} setType={setType} />
      </typeContext.Provider>
    );
  } else {
    return (
      <div className="grid-center">
        <Button variant="contained" size="large" onClick={() => setType("TAX")}>
          Tax Invoices
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => setType("PERFORMA")}
        >
          Performa Invoices
        </Button>
      </div>
    );
  }
}
