import React from "react";
import { Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Menu } from "@mui/material";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Navbar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [open, setOpen] = React.useState(false);
  let [showBack, setShowBack] = React.useState("none");
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  //move back to bill selection page
  function back() {
    props.setShowSingle(null);
    setShowBack("none");
  }

  useEffect(() => {
    if (props.setShowSingle) {
      setShowBack("block");
    }
  });

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{ display: { xs: "none", sm: "flex" }, displayPrint: "none" }}
      >
        <Button component={RouterLink} to="/new">
          Create New
        </Button>
        <Button
          component={RouterLink}
          to="/invoices"
          onClick={() => {
            if (props.setType) {
              props.setType("");
            }
          }}
        >
          Invoices
        </Button>
        <Button sx={{ display: showBack }} onClick={back}>
          Back
        </Button>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ display: { sm: "none" }, displayPrint: "none" }}
      >
        <IconButton color="primary" onClick={back} sx={{ display: showBack }}>
          <ArrowBackIcon />
        </IconButton>

        <IconButton color="primary" onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem component={RouterLink} to="/new">
            Create New
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to="/invoices"
            onClick={() => {
              if (props.setType) {
                props.setType("");
              }
            }}
          >
            Invoices
          </MenuItem>
        </Menu>
      </Stack>
    </>
  );
}
