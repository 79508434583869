import React from "react";
import Navbar from "../Navbar";
import InvoiceList from "./InvoiceList";
import { useState } from "react";
import Single from "./Single";
import { Typography } from "@mui/material";

export default function GetInvoices(props) {
  let [data, setData] = useState(null);
  let [gapiInited, setGapiInited] = useState(false);
  let [showSingle, setShowSingle] = useState(null);
  if (showSingle !== null) {
    return (
      <>
        <Navbar setShowSingle={setShowSingle} setType={props.setType}/>
        <Single details={data[Number(showSingle)]} />
      </>
    );
  } else {
    return (
      <>
        <Navbar setType={props.setType}/>
        <Typography variant="h2">{props.type} INVOICES</Typography> 
        <InvoiceList
          data={data}
          setData={setData}
          gapiInited={gapiInited}
          setGapiInited={setGapiInited}
          setShowSingle={setShowSingle}
          type={props.type}
        />
      </>
    );
  }
}
