import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect,useState } from "react";

export default function ItemTable(props) {
  let [items, setItems] = useState([]);
  let [total, setTotal] = useState(0)
  useEffect(() => {
    setItems(() => props.items)
    setTotal(() => {
      return (items.reduce((partialSum, item) => partialSum + ((Number(item.price))*(Number(item.quantity))),0))
    }) 

  })

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.itemName}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.price}</TableCell>
                <TableCell>{Math.round((Number(item.price))*(Number(item.quantity))*100)/100}</TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell colSpan={3}>
              <b>Total</b>
            </TableCell>
            <TableCell>{Math.round(total+(total*0.18))}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
