import React, { Component, useState } from "react";
import { Button } from "@mui/material";
import Select from "./Components/Select.js";
import GoogleIcon from "@mui/icons-material/Google";
import "./css/styles.css";
import { BrowserRouter } from "react-router-dom";

let client;

export default function App() {
  let [login, setLogin] = useState(false);
  async function afterInit() {
    await init();
    client.requestAccessToken();
  }
  function init() {
    client = window.google.accounts.oauth2.initTokenClient({
      client_id:
        "189595015346-gau8u8rob2dt6gusq0fli10uea44rhgo.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/spreadsheets",
      callback: (token) => {
        if (token.access_token) {
          setLogin(() => true);
        }
      },
    });
  }
  if (!login) {
    return (
      <div className="grid-center">
        <Button
          startIcon={<GoogleIcon />}
          onClick={afterInit}
          variant="contained"
          size="large"
        >
          Login with Google
        </Button>
      </div>
    );
  } else {
    return (
      <React.StrictMode>
        <BrowserRouter>
          <Select />
        </BrowserRouter>
      </React.StrictMode>
    );
  }
}
