import React, { Component } from 'react'

class ItemBill extends Component{
    render(){
        let amountArr = [];
        let rows = this.props.castors.map((item, index) => {
            return(
                <tr key={index} >
                    <td>
                        {item.itemName}
                    </td>
                    <td>7326</td>
                    <td>{item.quantity}</td>
                    <td>SETS</td>
                    <td>{item.price}</td>
                    <td>{Math.round(item.quantity*item.price*100)/100}</td>
                </tr>
            )
        });
        
        return rows
    }
}

export default ItemBill