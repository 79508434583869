import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Button, Stack } from "@mui/material";
import ShowDetails from "./ShowDetails";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import Navbar from "./Navbar";
import Type from "./Type"

let detailsContext = React.createContext({});
let typeContext = React.createContext("TAX");
export { detailsContext, typeContext };
export function Form() {
  let [parties, setPartiesArr] = useState([]);
  let [formStatus, setFormStatus] = useState(false);
  let [selectedParty, setSelectedParty] = useState({});
  let [billNumber, setBillNumber] = useState(0);
  let [retry, setRetry] = useState(0);
  let [addItem, setAddItem] = useState(false);
  let [type, setType] = useState("Tax")

  //Callback Function that adds more items
  function editState() {
    if (billNumber > 1) {
      setSelectedParty(() => {
        return {
          number: billNumber,
        };
      });
      setFormStatus(true);
      setAddItem(true);
    }
  }

  //Reset Callback
  function reset(event) {
    if (event.target.id == "reset") {
      setFormStatus(false);
      setAddItem(false);
    }
  }

  //Function for setting the latest Bill number from the retreived object
  function calculateNumber(obj) {
    let arr = obj.result.values;
    let len = arr.length;
    let lastBillNumber = arr[len - 1];
    setBillNumber(Number(lastBillNumber[0]) + 1);
  }

  //Callback function that fires when the input is selected
  async function partySelected() {
    try {
      let selected = document.getElementById("partySelectList");
      if (selected.value) {
        for (let i = 0; i < parties.length; i++) {
          if (selected.value == parties[i].label && billNumber > 0) {
            setSelectedParty(() => {
              let obj = parties[i];
              obj.number = billNumber;
              obj.date = document.getElementById("date").value;
              return obj;
            });
            setFormStatus(true);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  //initialize the gapi client and load sheets
  useEffect(
    async function () {
      try {
        await window.gapi.client.init({});
        await window.gapi.client.load("sheets", "v4");

        console.log("gapi-sheets loaded");

        let numberObj = await fetchBillNumber();
        calculateNumber(numberObj);
      } catch (err) {
        if (retry == 10) {
          alert("failed to fetch the bill number");
        } else {
          setRetry(retry + 1);
        }
      }
    },
    [retry]
  );

  //callback function for getting bill number
  async function fetchBillNumber() {
    try {
      let response = await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: "1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4",
        range: "Sheet2!A:A",
      });
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  //callback function for loading sheets
  async function fetch() {
    try {
      let response = await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: "1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4",
        range: "Sheet1!A1:C100",
      });
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchData() {
    try {
      let spreadsheetObj = await fetch();
      let values = await spreadsheetObj.result.values;

      let partiesArr = values.map((item, index) => {
        return {
          label: item[0],
          address: item[1],
          gst: item[2],
        };
      });
      // console.log(partiesArr)
      setPartiesArr(() => partiesArr);
    } catch (err) {
      alert("Network Error, try again");
      console.log(err);
    }
  }

  if (formStatus) {
    return (
      <>
        <Navbar />
        <detailsContext.Provider value={selectedParty}>
          <typeContext.Provider value ={type}>
          <ShowDetails
            {...selectedParty}
            setSelectedParty={setSelectedParty}
            addItem={addItem}
            reset={reset}
          />
          </typeContext.Provider>
        </detailsContext.Provider>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <Type
        type={type}
        setType={setType}
        />
        <Autocomplete
          disablePortal
          id="partySelectList"
          options={parties}
          loading={true}
          onOpen={fetchData}
          renderInput={(params) => (
            <TextField {...params} label="Select Party" />
          )}
          sx={{ mb: 1 }}
        />
        <input
          type="date"
          className="datePicker"
          id="date"
          placeholder="Date"
        ></input>
        <Stack direction="row" justifyContent="space-evenly">
          <Button
            onClick={partySelected}
            variant="contained"
            startIcon={<CheckIcon />}
          >
            Confirm
          </Button>
          <Button
            onClick={editState}
            variant="contained"
            color="success"
            startIcon={<AddCircleIcon />}
          >
            Add Party
          </Button>
        </Stack>
      </>
    );
  }
}
