import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useContext, useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { OutlinedInput, Button, Stack, Typography, DialogActions } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { detailsContext, typeContext } from '../Form';
import { LoadingButton } from '@mui/lab';
import Gst from '../Gst'
import Bill from '../Bill/Bill';

export default function ItemForm(props){

    let [gstType, setGstType] = useState(18);
    let [loading, setLoading] = useState(false);
    let [dialogActionText, setDialogActionText] = useState('save');

    //Set dialogue action to either print or save
    async function updateAction(){
        if (dialogActionText  == 'save'){
            let result = await upload();
            if(result){
                setDialogActionText('Print')
            }else{
                return
            }
        }else{
            window.print()
        }
    }

    //upload function
    async function upload(){
        let sheet;
        if(type == 'TAX'){
            sheet = '2'
        }else{
            sheet = '3'
        }
        setLoading(true)
        let priceUploadArray = props.castors.map((item, index) => item.price)
        let QuantityUploadArray = props.castors.map((item, index) => item.quantity)
        let castorUploadArray = props.castors.map((item, index) => item.itemName)
        let response;
        let valueRangeBody = {
            "values": [
                [selectedParty.number,selectedParty.date,selectedParty.label,castorUploadArray.join(),QuantityUploadArray.join(),priceUploadArray.join(),props.total,gstType,selectedParty.address,selectedParty.gst]
            ]
        }
        try{
            response = await window.gapi.client.sheets.spreadsheets.values.append({
                spreadsheetId: '1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4',
                range: `Sheet${sheet}!A1:H100`,
                valueInputOption: 'USER_ENTERED',
            }, valueRangeBody)
            alert('Update Success')
            setLoading(false)
            return response.status
        }catch(err){
            alert(err.result.error.message)
            console.log(err)
            setLoading(false)
        }
    }

    //using selectparty context from Form.js Component
    let selectedParty = useContext(detailsContext);
    let type = useContext(typeContext);

    const [open, setOpen] = useState(false);

    //display Confirm Dialog
    function confirmDialog(){
        setOpen(true);
    }
    //Dialog close function
    const handleClose = () => {
        setOpen(false);
    };

    let [castor, setCastor] = useState('');

    function selectCastor(event){
        setCastor(() => event.target.value);
    }

    //add castor to the state
    function handleClick(){
        let item = castor;
        let itemPrice = price;
        let itemQuantity = quantity;
        let castorCopy = [...props.castors];
        props.setCastors(() => {
            let obj = {
                itemName: item,
                quantity: itemQuantity,
                price: itemPrice,
            }
            castorCopy.push(obj);
            return castorCopy
        });
    }

    let [quantity, setQuantity] = useState(null);
    let [price, setPrice] = useState(null);


    //verify whether the number is greater than zero or not
    function numVerify(event){
        
        let n = event.target.value;
        
        
            if(n >= 1 && event.target.id == 'quantity'){
                setQuantity(n);
            }else if(n >= 1 && event.target.id == 'price'){
                setPrice(n)
            }else if(n <= 1 && event.target.id == 'quantity'){
                setQuantity('');
            }else{
                setPrice('');
            }

        }
    return (
        <>
        <FormControl fullWidth
        sx={{mt: 2, displayPrint: 'none'}}
        >
            <InputLabel id="selectCastors">Castor</InputLabel>
            <Select
            labelId="selectCastors"
            id="Castor"
            label="Castor"
            onChange={selectCastor}
            >
                <MenuItem value={'WHEEL BOX'}>WHEEL BOX</MenuItem>
                <MenuItem value={'SIXER FIX'}>SIXER FIX</MenuItem>
                <MenuItem value={'SIXER REVOLVING'}>SIXER REVOLVING</MenuItem>
                <MenuItem value={'TWO WHEELER'}>TWO WHEELER</MenuItem>
                <MenuItem value={'TWO WHEELER REVOLVING'}>TWO WHEELER REVOLVING</MenuItem>
                <MenuItem value={'SIXER BEARING'}>SIXER BEARING</MenuItem>
                <MenuItem value={'SIXER BEARING(REVOLVING)'}>SIXER BEARING(REVOLVING)</MenuItem>
                <MenuItem value={'MINI SIXER'}>MINI SIXER</MenuItem>                
                <MenuItem value={'MINI SIXER REVOLVING'}>MINI SIXER REVOLVING</MenuItem>
                <MenuItem value={'SINGLE WHEEL'}>SINGLE WHEEL</MenuItem>                
                

            </Select>
        </FormControl>
        <FormControl fullWidth sx={{mt: 2, displayPrint: 'none'}} >
            <InputLabel htmlFor='quantity'>Quantity</InputLabel>
            <OutlinedInput 
            type='number'
            name='quantity'
            id='quantity'
            value={quantity}
            onChange={numVerify}
            />
            
        </FormControl>
        <FormControl fullWidth sx={{mt: 2, displayPrint: 'none'}} >
            <InputLabel htmlFor='price'>Price</InputLabel>
            <OutlinedInput 
            type='number'
            name='price'
            id='price'
            value={price}
            onChange={numVerify}
            />

        </FormControl>
        
        <Gst
        gstType={gstType}
        setGstType={setGstType}
        />
        
        <Stack
        direction="row"
        justifyContent="space-evenly"
        sx={{mt: 1, displayPrint: 'none'}}
        >
            <Button
            variant='contained'
            startIcon={<SaveIcon/>}
            color='success'
            onClick={confirmDialog}
            >
                Save and print
            </Button>
            <Button
            variant='contained'
            startIcon={<AddBoxIcon/>}
            onClick={handleClick}
            >
                Add Item
            </Button>
        </Stack>

        {/* Confirm Dialog box */}

        <Dialog
        open={open}
        onClose={handleClose}
        sx={{displayPrint: 'none'}}
        >
            <DialogTitle>Confirm the entries before saving</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant='body1'>Date</Typography>
                    <Typography variant='body2'>{selectedParty.date}</Typography>
                    <Typography variant='body1'>Buyer</Typography>
                    <Typography variant='body2'>{selectedParty.label}</Typography>

                    {/* <Typography variant='body2'>{selectedParty.address}</Typography> */}

                    <Typography variant='body1'>GST Number</Typography>
                    <Typography variant='body2'>{selectedParty.gst}</Typography>
                    <Typography variant='h5' sx={{mt: 1}}>Total: {props.total}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                onClick={updateAction}
                loading={loading}
                >
                    {dialogActionText}
                </LoadingButton>
                <Button
                onClick={handleClose}
                >Cancel</Button>
            </DialogActions>
        </Dialog>
        

        {/* Final Bill component */}
        <Bill
        castors = {props.castors}
        total={props.total}
        gstType={gstType}
        {...selectedParty}
        type={type}
        />
        </>
        
    )
} 