import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Button } from "@mui/material";

let initId;
let fetchId;
export default function InvoiceList(props) {
  let [loading, setloading] = useState(true);

  function handleClick(event) {
    props.setShowSingle(Number(event.target.id));
  }
  //get data from backend on mounting of component

  useEffect(() => {
    if (props.data == null) {
      if (props.gapiInited) {
        fetchId = setInterval(fetchData, 2000);
      } else {
        initId = setInterval(initInterval, 1000);
      }
    } else {
      // props.setGapiInited(true);
      setloading(false);
    }
  });

  //Gapi initialization loop
  async function initInterval() {
    try {
      window.gapi.client
        .init({})
        .then(() => window.gapi.client.load("sheets", "v4"))
        .then(() => {
          clearInterval(initId);
          props.setGapiInited(true);
        });
    } catch (err) {
      console.log(err);
    }
  }

  //Fetch details after the initialization of gapi client
  async function fetchData() {
    let sheetNumber;
    if (props.type == "TAX") {
      sheetNumber = "2";
    } else if (props.type == "PERFORMA") {
      sheetNumber = "3";
    } else {
      sheetNumber = "2";
    }
    try {
      let response = await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: "1KmvE770OYGesbgUU2sasugBHXYQYPtvjorm9sUVJIu4",
        range: `Sheet${sheetNumber}!A2:J200`,
      });
      console.log(response)
      removeInerval(response);
      if(response.result.values){
        props.setData(() => response.result.values.reverse());
      setloading(false);
      return response;
      }else{
        throw new Error('No Data')
      }
      
    } catch (err) {
      alert(err)
      console.log(err);
    }
  }

  if (loading) {
    return (
      <div className="grid-center-loading">
        <Typography variant="body1">Loading Data</Typography>
        <LinearProgress />
      </div>
    );
  } else {
    return (
      <>
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Bill Number</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((item, index) => {
                if (item[0]) {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item[0]}</TableCell>
                      <TableCell>{item[1]}</TableCell>
                      <TableCell>{item[2]}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          id={index}
                          onClick={handleClick}
                        >
                          more details
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

function removeInerval(obj) {
  clearInterval(fetchId);
}
