import ItemBill from './ItemBill';
import GstType from './GstType';
import numWords from 'num-words';
import '../../css/styles.css'
import { useEffect, useState } from 'react';

function titleCase(str) {
    let strLowerCase = str.toLowerCase();
    let wordArr = strLowerCase.split(" ").map(function(currentValue) {
        return currentValue[0].toUpperCase() + currentValue.substring(1);
    });

    return wordArr.join(" ");
}

export default function Bill(props){

    //Calculate the individual total of items without GST
    let [nogstTotal, setNogstTotal] = useState(0);
    useEffect(() => {
        let nogstTotalAry = props.castors.map((item, index) => {
            return (Number(item.price)*Number(item.quantity))
        })
        let temp = nogstTotalAry.reduce((partialSum, a) => partialSum + a, 0)
        setNogstTotal(Math.round(temp*100)/100);
    }, [props.total])
        return (
            <table className='main'>
                <tbody>
                <tr className='center'>
                    <td colSpan={6}>{props.type} INVOICE</td>
                </tr>
                <tr>
                    <td colSpan={6} className='center'><h1>Takkar Technico Industry</h1></td>
                </tr>
                <tr className='center'>
                    <td colSpan={6}>Manufacturers of Sheet metal, Plastic & Rubber components, Castor wheel etc.</td>
                </tr>
                <tr className='center'>
                    <td colSpan={6}>128, Greater  kailash colony, Jandli, Ambala City-134003(Haryana)</td>
                </tr>
                <tr className='center bold'>
                    <td colSpan={6}>GST  T.I.N.-06ADVPT7284R1Z2</td>
                </tr>
                <tr>
                    <td colSpan={6}></td>
                </tr>
                <tr className='bold'>
                    <td>Bill to</td>
                    <td colSpan={3}>Place of supply</td>
                    <td rowSpan={2} className='center'>INVOICE NO.</td>
                    <td rowSpan={2} className='center'>Dated</td>
                </tr>
                <tr className='bold'>
                    <td>{props.label}</td>
                    <td colSpan={3}>{props.label}</td>

                </tr>
                <tr>
                    <td>{props.address}</td>
                    <td rowSpan={2} colSpan={3}>{props.address}</td>
                    <td rowSpan={2} className='center'>{props.number}</td>
                    <td rowSpan={2} className='center bold'>{props.date}</td>

                </tr>
                <tr>
                    <td>GST Tin No: {props.gst}</td>
                </tr>
                <tr className='bold h'>
                    <td>Description of Service</td>
                    <td>HSN CODE</td>
                    <td>QTY</td>
                    <td>Units</td>
                    <td>RATE</td>
                    <td>Amount</td>
                </tr>
                <tr className='h und bold'>
                    <td>Description</td>
                    <td colSpan={4}></td>
                    <td></td>

                </tr>
                <ItemBill castors={props.castors}/>
                <tr className='bold'>
                    <td className='double'>Total</td>
                    <td colSpan={4}></td>
                   
                    <td className='double'>{nogstTotal}</td>
                </tr>
                <tr className='h'>
                    <td colSpan={6}></td>
                </tr>
                <tr className='bold'>
                    <td className='double'>Taxable Value</td>
                    <td colSpan={4}></td>
                    <td className='double'>{nogstTotal}</td>

                </tr>
                <tr></tr>
                <GstType GstType={props.gstType} total={nogstTotal}/>
                <tr>
                    <td className='h2' colSpan={6}></td>
                </tr>
                <tr className='bold'>
                    <td colSpan={5}>Total</td>
                    <td>{Math.round((nogstTotal+(nogstTotal*0.18))*100)/100}</td>
                </tr>
                <tr className='bold'>
                    <td colSpan={5}>Round Off</td>
                    <td>{Math.round(nogstTotal+(nogstTotal*0.18))}</td>
                </tr>
                <tr className='bold'>
                    <td colSpan={6}>Amount Chargeable in words</td>
                </tr>
                <tr>
                    <td colSpan={5}>{titleCase(numWords(Math.round(nogstTotal+(nogstTotal*0.18))))} Only</td>
                    <td colSpan={1}>For Takkar Technico Industry</td>
                </tr>
                <tr className='h'>
                    <td colSpan={6}></td>
                </tr>
                
                <tr>
                    <td colSpan={5}></td>
                    <td>Authorized Signature</td>
                </tr>

                </tbody>
                
            </table>
        )
}