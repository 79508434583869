import React, { Component } from 'react'

class GstType extends Component{
    render(){
        let row;
        if(this.props.GstType == 18){
              row = <tr >
                    <td className='bold'>ADD IGST 18%</td>
                    <td colSpan={3}></td>
                   
                    <td>18%</td>
                    <td>{Math.round(this.props.total*0.18*100)/100}</td>
                    </tr>
        }else{
            row = <>
                    <tr >
                     <td className='bold'>ADD CGST 9%</td>
                         <td colSpan={3}></td>
                         
                         <td>9%</td>
                         <td>{Math.round(this.props.total*0.09*100)/100}</td>
                     </tr>
                     <tr >
                         <td className='bold'>ADD SGST 9%</td>
                         <td colSpan={3}></td>
                         
                         <td>9%</td>
                         <td>{Math.round(this.props.total*0.09*100)/100}</td>
                     </tr>
                    </>
        }
      return row
        
    }
}

export default GstType